exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aims-js": () => import("./../../../src/pages/aims.js" /* webpackChunkName: "component---src-pages-aims-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tompiler-js": () => import("./../../../src/pages/tompiler.js" /* webpackChunkName: "component---src-pages-tompiler-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */)
}

